<template>
  <div v-if="!loading">
    <v-btn
      color="primary"
      rounded
      @click="add_member_dialog()"
      fab
      absolute
      top
      right
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("name") }}</th>
          <th>{{ $t("member_position") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(team_member, index) in team" :key="index">
          <td>{{ team_member.Name }}</td>
          <td>
            <div v-if="gs.isJson(team_member.Position)">
              <p
                class="mb-1"
                v-for="(k, i) in Object.keys(JSON.parse(team_member.Position))"
                :key="i"
              >
                <b style="font-weight: bold">{{ `${k.toUpperCase()}: ` }}</b
                >{{ `${JSON.parse(team_member.Position)[k]}` }}
              </p>
            </div>
            <span v-else>{{ team_member.Position }}</span>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_member_dialog(team_member)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(team_member)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <v-card>
        <v-card-title>
          <h4 class="dxa_modal_title h4">{{ $t("manage_team") }}</h4>
        </v-card-title>
        <v-card-text v-if="!dialog_loading">
          <v-form @submit.stop.prevent="add_member()" ref="form" class="mt-5">
            <v-select
              :items="index_options"
              :label="$t('index')"
              v-model="member.Index"
              outlined
            ></v-select>
            <v-row>
              <v-col cols="12" md="3">
                <div class="text-center">
                  <v-avatar class="memberImage" @click="openFilePicker">
                    <v-img
                      style="cursor: pointer"
                      v-if="member.Base64Image"
                      :src="member.Base64Image"
                    ></v-img>
                    <v-img
                      style="cursor: pointer"
                      v-else-if="member.Image"
                      :src="gs.get_photo_path(member.Image)"
                    ></v-img>
                    <v-img
                      style="cursor: pointer"
                      src="../../../../../../assets/profile.png"
                      v-else
                    ></v-img>
                    <input
                      id="imageMemberInput"
                      name="imageMemberInput"
                      ref="imageMemberInput"
                      type="file"
                      accept="image/png, image/jpeg, image/bmp"
                      style="display: none"
                      @change="setImageMember"
                    />
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div class="ml-4">
                  <v-text-field
                    :label="$t('name')"
                    :rules="[required]"
                    v-model="member.Name"
                  />

                  <v-row>
                    <v-col cols="2">
                      <v-select
                        :items="availableLangs"
                        :label="$t('language')"
                        v-model="selectedLang"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        :label="$t('member_position')"
                        v-model="member.Position[selectedLang]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                :loading="dialog_loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="red"
                min-width="100"
                @click="dialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-alert class="ma-2" v-if="error" type="error">{{
            $t(error)
          }}</v-alert>
        </v-card-text>
        <v-row justify="center" class="py-2" v-else>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="remove_member"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.memberImage {
  height: 135px !important;
  min-width: 135px !important;
  width: 48px !important;
  border-radius: 50% !important;
  z-index: 20;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "ManageCompanyTeam",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    company_id: null,
    error: null,
    edit: false,
    team: [],
    selectedLang: null,
    availableLangs: [],
    member: {
      Name: null,
      Position: null,
      Index: null,
      Image: null,
    },
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (i = 1; i <= this.team.length; i++) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(this.team.length + 1);
      }
      return return_list;
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.availableLangs = Object.keys(this.$i18n.messages);
    await this.get_company_briefing_team();
  },

  methods: {
    async get_company_briefing_team() {
      this.loading = true;
      await this.apiService
        .getRequest(`companyteam/list/${this.BriefingId}`)
        .then((resp) => {
          this.team = JSON.parse(resp.message);
        });
      this.loading = false;
    },
    openFilePicker() {
      this.$refs.imageMemberInput.click();
    },
    setImageMember(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.member.Base64Image = reader.result;
      };
    },
    open_delete_dialog: function (member) {
      this.delete_obj = member;
      this.delete_dialog = true;
    },
    remove_member: async function (member) {
      this.dialog_loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest(`companyteam/delete/${member.Id}`)
        .then((result) => {
          this.team = JSON.parse(result.message);
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
    add_member_dialog: function () {
      this.edit = false;
      this.member = {
        Name: null,
        Position: {},
        CompanyId: this.company_prop.CompanyId,
        Image: null,
        Base64Image: null,
        BriefingId: this.BriefingId,
        Index: this.team.length + 1,
      };
      for (var i = 0; i < this.availableLangs.length; i++) {
        this.member.Position[this.availableLangs[i]] = "";
      }
      this.selectedLang = this.$i18n.locale;
      this.dialog = true;
    },
    edit_member_dialog: function (member) {
      this.edit = true;
      this.member = {
        Id: member.Id,
        CompanyId: member.CompanyId,
        Name: member.Name,
        Position: {},
        Index: member.Index,
        Image: member.Image,
        BriefingId: member.BriefingId,
        Base64Image: null,
      };
      if (this.gs.isJson(member.Position)) {
        this.member.Position = JSON.parse(member.Position);
      } else {
        this.member.Position = {
          pt: member.Position,
        };
      }
      for (var i = 0; i < this.availableLangs.length; i++) {
        if (!this.member.Position[this.availableLangs[i]]) {
          this.member.Position[this.availableLangs[i]] = "";
        }
      }
      this.selectedLang = this.$i18n.locale;
      this.dialog = true;
    },
    checkForm: function () {
      this.error = null;
      var result = this.$refs.form.validate();
      if (result) {
        this.add_member();
      } else {
        this.dialog_loading = false;
      }
    },
    add_member: async function () {
      if (this.dialog_loading) {
        return;
      }
      this.dialog_loading = true;

      this.member.Position = JSON.stringify(this.member.Position);

      if (this.edit) {
        await this.apiService
          .putRequest("companyteam/edit", this.member)
          .then((result) => {
            this.team = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        await this.apiService
          .postRequest("companyteam/add", this.member)
          .then((result) => {
            this.team = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.dialog_loading = false;
    },
  },
};
</script>
